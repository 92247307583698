import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './index.scss';

import TrackPageViews from './google';

//
import DefaultHeader from './containers/default-header';
import LeftNav from './containers/left-nav';
import DefaultFooter from './containers/default-footer';

// Pages
const Home = React.lazy(() => import('./views/home'));
const Coronavirus = React.lazy(() => import('./views/coronavirus'));
const CovidForbearance = React.lazy(() => import('./views/covidforbearance'));

//
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {

  render() {

    return (
      <div>

        <DefaultHeader />

        <LeftNav/>

        <div className="container-fluid">
          <main>

              <BrowserRouter>
                <React.Suspense fallback={loading()}>                    
                  <TrackPageViews/>
                  <Switch>
                    <Route exact path="/coronavirus" render={props => <Coronavirus/>} />
                    <Route exact path="/covidForbearance" render={props => <CovidForbearance/>} />
                    <Route path="/" render={props => <Home/>} />
                  </Switch>
                </React.Suspense>
              </BrowserRouter>

            <DefaultFooter />
          </main>
        </div>

      </div>
     );
    }
  }
  
  export default App;
