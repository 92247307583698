import React, { Component } from 'react';
import './default-footer.scss';

export default class DefaultFooter extends Component {
  render() {

    return (
      <div>

        <footer className="footer">
          <div className="container-fluid">
            
            <div className="row">
              <div className="col pb-4">
                <img src="/img/rp_logo_footer.png" alt="RoundPoint Mortgage Servicing Corporation (Footer Logo)"/>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <hr className="footer-divider"/>
              </div>
            </div>
          
            <div className="row">

              <div className="col-4 footer-content">
                <p>
                  <a rel="noopener noreferrer" href="https://www.linkedin.com/company/roundpoint-financial-group/" target="_blank">
                    <img alt="linkedin logo" src="/img/linkedin_footer.png" className="p-2" />
                  </a>
                  <a rel="noopener noreferrer" href="https://www.hud.gov/program_offices/fair_housing_equal_opp" target="_blank">
                    <img alt="equal housing opportunity logo" src="/img/eho_footer.png" className="p-2" />
                  </a>
                  <a rel="noopener noreferrer" href="https://www.aicpa.org/interestareas/frc/assuranceadvisoryservices/sorhome.html" target="_blank">
                    <img alt="AICPA logo" src="/img/aicpa_footer.png" className="p-2" />
                  </a>
                </p>

                <p>
                  &copy; 2020 RoundPoint Mortgage<br/>
                  RoundPoint Mortgage Servicing Corporation is a Washington Consumer Loan Company Licensee
                </p>

                <p>
                  <a rel="noopener noreferrer" href="https://www.nmlsconsumeraccess.org/" target="_blank">
                    <strong>NMLS ID 18188</strong>
                  </a>
                  <br/>
                  <small>For additional information, please visit:</small><br/>
                  <a rel="noopener noreferrer" href="https://www.nmlsconsumeraccess.org/" target="_blank">
                    <small>NMLS CONSUMER ACCESS</small>
                  </a>
                </p>

                <p>
                  For more information on mortgage assistance, contact:<br/>
                  <a rel="noopener noreferrer" href="http://hud.gov/counseling" target="_blank">
                    <strong>HUD&apos;s COUNSELING CENTER</strong>
                  </a>
                  &nbsp;
                  <a href="tel:1-800-569-4287">
                  <strong>(800) 569-4287</strong>
                  </a>
                </p>

              </div>


              <div className="col footer-content">

                <div className="footer-header">
                  Quick Links
                </div>

                <ul className="footer-list">
                  <li><a href="https://servicinghome.customercarenet.com/ccn/servicinghome/mymortgage.html#HOME-C" rel="noopener noreferrer" title="Log In" target="_blank">LOG IN</a></li>
                  <li><a href="https://getaloan.rpmservicing.com/" rel="noopener noreferrer" title="Get a Loan" target="_blank">Get a Loan</a></li>
                  <li><a href="/marketplace" title="Marketplace">Marketplace</a></li>
                  <li><a href="/contact-us" className="contactus hidden">Contact Us</a></li>
                  <li><a href="https://api.five9.com/ivr/1/109388/campaigns/1140031/new_ivr_session" className="vivrcontainer" rel="noopener noreferrer" target="_blank" title="Contact Us">Contact Us</a></li>
                  <li><a href="/payments/paymenthelp">Payment Help</a></li>
                </ul>
              </div>


              <div className="col footer-content">

                <div className="footer-header">
                  Company
                </div>

                <ul className="footer-list">
                  <li><a href="/about" title="About Us">About Us</a></li>
                  <li><a href="/careers" title="Careers">Careers</a></li>
                  <li><a href="/businesssolutions" title="Clients and Sellers">Clients/Sellers</a></li>
                  <li><a href="/culture" title="Culture">Culture</a></li>
                  <li><a href="/press">PRESS</a></li>
                </ul>
              </div>


              <div className="col footer-content">
                
                <div className="footer-header">
                  Resources
                </div>

                <ul className="footer-list">
                  <li><a href="/payments" title="Payments">Payments</a></li>
                  <li><a href="/newcustomers" title="New Customers">New Customers</a></li>
                  <li><a href="/forms" title="Forms">Forms</a></li>
                  <li><a href="/faq" title="FAQ">FAQ</a></li>
                  <li><a href="/glossary" title="Glossary">Glossary</a></li>
                  <li><a href="/ombudsman" title="Consumer Ombudsmen">Consumer Ombudsmen</a></li>
                </ul>
              </div>


              <div className="col footer-content">
                
                <div className="footer-header">
                  Legal
                </div>

                <ul className="footer-list">
                  <li><a href="/fees" title="Fees">Fees</a></li>
                  <li><a href="/licensing" title="Licensing">Licensing</a></li>
                  <li><a href="/privacy" title="Privacy">Privacy</a></li>
                  <li><a href="/california-notice-and-disclosures">California Privacy</a></li>
                  <li><a href="/terms" title="Terms">Terms</a></li>
                </ul>
              </div>


            </div>

          </div>

        </footer>

        <div className="disclaimer p-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="disclaimer-text">
                  <p>
                    The Personal Household Economy&trade; You understand and acknowledge that (a) the purchase of insurance or other similar products from a 
                    particular source is not a condition to the provision of, and will not affect the terms of any loan or extension of credit by RoundPoint 
                    Mortgage Servicing Corporation (&quot;RoundPoint&quot;) or its affiliates, (b) insurance and other similar products are available through insurance 
                    agents not associated with Round Point or any of their affiliates, (c) the choice of another insurance provider will not affect a decision 
                    relating to, or the terms of, any loan or extension of credit by or its affiliates and (d) you accept and assume full responsibility for the 
                    evaluation, selection and suitability of insurance or other similar products. 
                    
                    Insurance products are offered through RoundPoint Mortgage Solutions, LLC, an affiliate of RoundPoint (collectively RoundPoint) (and powered 
                    by Matic Insurance Services, Covr Financial Technologies, and C&amp;F Insurance Agency). Neither RoundPoint, RoundPoint Mortgage Solutions, nor 
                    any of their U.S. affiliates underwrite any policy of insurance. Insurance products may not be available in all states. RoundPoint may be 
                    compensated in connection with these programs. By clicking the above links, you will leave RoundPoint Mortgage Servicing Corporation website 
                    and go to a website operated by a third party. RoundPoint does not provide, nor is it liable for, any of the products, services or content on 
                    the third party website. RoundPoint encourages you to review the third party&apos;s privacy policy and security level, which may differ 
                    from that of RoundPoint.    You understand and acknowledge that (a) the purchase of home services or other similar products from a 
                    particular source is not a condition to the provision of, and will not affect the terms of any loan or extension of credit by 
                    RoundPoint Mortgage Servicing Corporation (&quot;RoundPoint&quot;) or its affiliates, (b) home services and other similar products are 
                    available through providers not associated with RoundPoint or any of their affiliates, (c) the choice of another home service 
                    provider will not affect a decision relating to, or the terms of, any loan or extension of credit by or its affiliates and (d) you 
                    accept and assume full responsibility for the evaluation, selection and suitability of home services or other similar products. Home service 
                    products are offered through RoundPoint Mortgage Solutions, LLC, an affiliate of RoundPoint (collectively RoundPoint), and powered 
                    by Bridgevine,lnc, d/b/a Updater Home Services and Assero Services. Neither RoundPoint, RoundPoint Mortgage Solutions, nor any of 
                    their U.S. affiliates underwrite any home service product. Home service products may not be available in all states. RoundPoint may be 
                    compensated in connection with these programs. By clicking the above links, you will leave RoundPoint Mortgage Serving Corporation 
                    website and go to a website operated by a third party. RoundPoint does not provide, nor is it liable for, any of the products, services 
                    or content on the third party website. RoundPoint encourages you to review the third party &apos;s privacy policy and security level, 
                    which may differ from that of RoundPoint.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}