import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import './default-header.scss';

export default class DefaultHeader extends Component {
  render() {

    return (
      <div>

        <header className="">

          <div className="row flex-nowrap">
            
            <div className="col pt-3 pl-5">
              <a href="/">
                <img
                  src="/img/rp_logo.png"
                  alt="RoundPoint Mortgage Servicing Corporation"
                />
              </a>
            </div>

            <div className="col-auto pt-3 pr-5 hidden-xs hidden-sm">

              <div id="nav">
                <Nav fill>
                  <Nav.Item>
                    <Nav.Link href="#" className="nav-link">
                      SEARCH
                    </Nav.Link>
                  </Nav.Item>
                                    
                  <Nav.Item>
                    <Nav.Link href="https://servicinghome.customercarenet.com/ccn/servicinghome/mymortgage.html#HOME-C" className="nav-link">
                      LOG IN
                    </Nav.Link>
                  </Nav.Item>
                  
                  <Nav.Item className="nav-pay-now">
                    <Nav.Link href="https://roundpointwebpay.secureconduit.net/" className="nav-link nav-pay-now-link">
                      PAY NOW
                    </Nav.Link>
                  </Nav.Item>
                  
                </Nav>
              </div>
              

            </div>
          </div>
        </header>

      </div>
    )
  }
}
