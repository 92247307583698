import React, { Component } from 'react';
import './left-nav.scss';

export default class LeftNav extends Component {
  render() {

    return (
      <div>

        <div id="left-nav">

          <ul className="left-nav-menu">
            <li className="left-nav-item">
              <a href="/coronavirus">COVID-19 UPDATES</a>
            </li>
            <li className="left-nav-item">
              <a href="https://servicinghome.customercarenet.com/ccn/servicinghome/mymortgage.html#HOME-C">I HAVE A LOAN</a>
            </li>
            <li className="left-nav-item">
              <a href="https://getaloan.rpmservicing.com/">I NEED A LOAN</a>
            </li>
            <li className="left-nav-item">
              <a href="/marketplace">MARKETPLACE</a>
            </li>
            <li className="left-nav-item">
              <a href="/payments">PAYMENTS</a>
            </li>
            <li className="left-nav-item">
              <a href="/insurance">INSURANCE</a>
            </li>
            <li className="left-nav-item">
              RESOURCES
            </li>
            <li className="left-nav-item">
              <a href="/newcustomers">NEW CUSTOMERS</a>
            </li>
          </ul>

        </div>



      </div>
    )
  }
}
